/* TODO Add site wide styles */
* {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .8);
    font-family: 'Roboto Slab', serif;
}

html, body, :root {
    height: 100vh;
}

button {
    cursor: pointer;
}

.homepage {
    /* border: 1px solid black; */
    /* border-radius: 3px; */
    display: flex;
    /* padding: 10px; */
    background-image: url(https://www.highlysprungsofas.co.uk/wp-content/uploads/2016/03/HSS00A9.png);
    background-position: center;
    background-size: contain;
    background-color: rgba(0, 0, 0, .7);
    height: 100vh;
}

.user-nav {
    height: fit-content;
    width: 15%;
    margin: .5rem;
}

.current-option {
    width: 85%;
    margin: .5rem;
    margin-left: 0;
    padding: .3rem;
    display: flex;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, .7);
}


.current-option-content {
    height: 100%;
    /* border: 1px solid black; */
    width: 80%;
    margin-left: .5rem;
    border-radius: 3px;
}

.current-option-options {
    height: fit-content;
    border: 1px solid black;
    width: 95%;
    display: flex;
    padding: 5px;
    border-radius: 3px;
    align-items: center;
    margin-bottom: 2rem;
}

.current-option-content-details {
    display: flex;
    flex-direction: column;
    padding: .5rem;
    background-color: rgba(211, 211, 211, .7);
    border-radius: 3px;
    max-height: 94.8vh;
    height: 100%;
}

.edit-container {
    background-color: white;
    /* border: 1px solid black; */
    height: fit-content;
    padding: 5px;
    border-radius: 3px 3px 0 0 ;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
}

.edit-container-area {
    /* border: 1px solid black; */
    border-top: 1px solid rgba(211, 211, 211, .7);
    /* height: 53.9r? */
    border-radius: 0 0 3px 3px;
    background-color: white;
    padding: 1rem;
    height: 100%;
}

.add-and-notebooks {
    padding: .3rem;
    border: 1px solid black;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15rem;
    background-color: white;
    /* height: 100%; */
}

.notebook-list-item {
    display: flex;
    width: 13.6rem;
    justify-content: space-between;
    margin:5px 0;
    cursor: pointer;
}

.new-notebook {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px;
    align-items: center;
}

/* .name-input {
    width: 100%;
} */

.note-book-heading {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    padding-bottom: 3px;
    border-bottom: 1px solid black;
}

.edit-name {
    display: flex;
}

.edit-button {
    margin-right: 5px;
    background-color: transparent;
    border: none;
}

.delete-button {
    background-color: transparent;
    border: none;
}

.fire-button:hover {
    color: red;
}

.text-area-note {
    width: 100%;
    height: 85vh;
    font-size: 16px;
    border: none;
}

.note-edit-name {
    border: none;
    border-bottom: 1px solid black;
    width: max-content;
    display: flex;
    justify-content: center;
}

.note-heading {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid black;

    margin-bottom: 8px;
    padding-bottom: 3px;
}

.add-note-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.add-button {
    margin: 5px;
    background-color: transparent;
    border-radius: 5px;
    padding: 2px;
}

/* .note-delete-and-edit-container {
    width: 5rem;
    display: flex;
    justify-content: space-between;
} */

#delete-note {
    margin-right: 15px;
}

.margin-delete {
    margin-right: 5px;
}

.hello-user {
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    background-color: white;
    padding: 5px;
    margin-bottom: 1rem;
}

.browser-user-nav {
    border-radius: 3px;
    background-color: white;
    padding: 5px;
}

.browser-heading {
    border-bottom: 1px solid rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    padding-bottom: 3px;
    margin-bottom: 5px;
}

.not-logged-in {
    background-image: url(https://www.highlysprungsofas.co.uk/wp-content/uploads/2016/03/HSS00A9.png);
    background-position: center;
    background-size: contain;
    background-color: rgba(0, 0, 0, .7);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-signup {
    background-color: white;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    border-radius: 5px;
}

.login-signup-input {
    display: flex;
    justify-content: space-between;
    margin: .2rem 1rem;
    width: 17rem;
}

.bibliotheca-login-welcome {
    font-size: 20px;
    margin-bottom: 2rem;
    border-bottom: 1px double black;
}

.bibliotheca-logo-png {
    width: 150px;
    margin-bottom: 15px;
}

.login-buttons {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 90%;
    border-radius: 5px;
}

.login-button {
    padding: 3px;
    background-color: rgba(211, 211, 211, .2);
    border: 1px solid black;
}

#login {
    border-radius: 5px 5px 0 0;
}

#demo-login {
    border-radius: 0 0 5px 5px;
}

.login-button:hover {
    background-color: lightgrey;
}

.register-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-link-words {
    font-size: 12px;
}

.signup-input {
    display: flex;
    width: 21rem;
    justify-content: space-between;
    margin: .2rem 1rem;
}

.signup-button {
    width: 90%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding: 3px;
    background-color: rgba(211, 211, 211, .2);
    border: 1px solid black;
    border-radius: 5px;
}

.signup-button:hover {
    background-color: lightgrey;
}

.error {
    color: red;
}

.error-list {
    margin-top: -1rem;
    margin-bottom: 1rem;
    width: 19rem;
}

.built-in {
    display: flex;
    justify-content: center;
}

*:disabled {
    cursor: not-allowed;
}

.notebook-edit {
    padding: 1px;
    padding-right: 3px;
    padding-left: 5px;
    border-top: 1px solid grey;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    border-radius: 3px;
}

.note-body-container {
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    word-break: break-word;
}

.browser {
    height: 94.9vh;
    width: 85%;
    margin: .5rem;
    margin-left: 0;
    padding: .83rem;
    display: flex;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, .7);
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
}

.browser-choice {
    border: 1px solid rgba(0, 0, 0, .7);
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 25px;
    background-color: white;
}

.browser-details {
    display: flex;
    justify-content: space-between;
    padding: 0 .5rem;
    border: 1px solid rgba(0, 0, 0, .7);
    /* border-top: none; */
    border-radius: 3px;
    height: 100%;
    background-color: white;
}

.option-list {
    width: 35%;
    border-right: 1px solid rgba(0, 0, 0, .7);
    height: 100;
    padding: 10px 0;

}

.option-list-item {
    padding: 5px;
    font-size: 18px;
}

.option-details {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 15px;
    flex-direction: column;
    justify-content: center;
}

.option-dates {
    display: flex;
    flex-direction: column;
    margin: 20px 0 30px 0;

}

.date {
    display: flex;
    width: 18.3rem;
    justify-content: space-between;
}

.option-date-container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.twtypxtext {
    font-size: 20px;
}

.mrgbtmtrtypx {
    margin-bottom: 30px;
}

.mrgbtmtenpx {
    margin-bottom: 10px;
}

.note-preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .7);
    border-radius: 3px;
    width: 80%;
    padding: 3rem 2rem;
    height: fit-content;
}

.btmbrdr {
    border-bottom: 1px solid rgba(0, 0, 0, .7);
}

.cntr {
    max-width: 40rem;
    font-size: 18px;
}

.mrgnbtm1rem {
    margin-bottom: 1rem;
}

.associated-notes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.associated-list-items {
    font-size: 22px;
    margin: 5px;
    display: flex;
    justify-content: center;
}

.edit-tag-button {
    border: none;
    margin-right: 5px;
    background-color: transparent;
}

.dsplyflx {
    display: flex;
}

.update-tag-form {
    width: 28rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 3px;
    padding-bottom: 4rem;
}

.exit-modal-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
}

.edit-tag-name {
    margin: 1rem 0;
    height: 50px;
    width: 80%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .7);
}

.exit-save-button {
    background-color: transparent;
    border: none;
}

.handpointer {
    cursor: pointer;
}

.regpointer {
    cursor: default;
}
